<template>
  <v-dialog v-model="internalIsOpen" max-width="600px">
    <v-card>
      <v-card-title>
       Enviar invitación
      </v-card-title>
      <v-card-text class="bg-surface-light pt-4">
        Se enviará una invitación para realizar el pago pendiente al titular mediante el <strong>correo ingresado</strong>
        anexando el recibo de pago.
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="sendEmail">
          <v-text-field v-model="email" label="Correo electrónico" :rules="[rules.required, rules.email]"
            required></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModal">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="sendEmail">Enviar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/plugins/event-bus'; // Importa el bus de eventos

export default {
  computed: {
    ...mapGetters(['getEmail'])
  },
  mounted() {
    this.email = this.localItem.Email ? this.localItem.Email: this.getEmail
  },
  props: {
    isOpen: Boolean,
    item: Object
  }, 
  watch: {
    isOpen(val) {
      this.internalIsOpen = val;
      if (val) this.localItem = { ...this.item };
    },
    internalIsOpen(val) {
      this.$emit("close", val);
    },
  },
  data() {
    return {
      internalIsOpen: this.isOpen,
      localItem: { ...this.item },
      dialog: false,
      valid: false,
      email: '',
      subject: '',
      message: '',
      rules: {
        required: value => !!value || 'Requerido.',
        email: value => {
          const pattern = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/
          return pattern.test(value) || 'Correo electrónico inválido.'
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.internalIsOpen = false;
    },
    sendEmail() {
      this.$loading(true)
      if (this.$refs.form.validate()) {
        fetch(`${process.env.VUE_APP_BUCKETFORMATOS}ReciboMedicover/SendMail/${this.email}/${this.localItem.idpago}`, {
          method: 'GET'
        })
          .then(response => {
            this.$loading(false)
            if (response.status === 200) {
              this.$loading(false)
              EventBus.$emit('show-snackbar', {
                text: `El correo fue enviado correctamente.`,
                color: 'success',  // Puedes cambiar el color
                timeout: 5000   // Puedes ajustar el tiempo
              });
            }else{
              this.$swal({
                position: '',
                html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> . ${response.statusText}`,
                icon: 'error',
                confirmButtonText: 'Cerrar',
                showCloseButton: true,
                showConfirmButton: true,
                //timer: 1500,
              })
            }
            this.$loading(false)
            return response.status 
          })

          .catch(error => {
            this.$loading(false)
            this.$swal({
              position: '',
              html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> . ${error}`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
              //timer: 1500,
            })
          });
      }
      
      // Reiniciar el formulario y cerrar el modal
      // this.email = '';
      this.closeModal();
    }
  }
}
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>