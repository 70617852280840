<template>
    <v-container fluid>
     <div v-if="this.recibos.length > 0">      
        <tabla-pagos 
        :recibos="this.recibos" 
        :actions="tableActions"
        @action-selected="onActionSelected">
      </tabla-pagos>
    </div>
     <div v-else>
        <div class="title-container">
        <!-- Ícono y texto -->
        <v-icon class="title-icon">mdi-check-circle</v-icon>
        <span class="title-text">Tu membresía no tiene recibos asociados, 
            disfruta de la seguridad de contar con las coberturas médicas para ti y tu familia.</span>
        </div>
     </div>
    </v-container>
  </template>
  <script>
  import { mapGetters } from 'vuex'
  import EndPointNet from "@/functions/axios/axios-bearer";
  
  
  export default {
    name: 'UserRecibos',
    components: {
      TablaPagos: () => import('@/components/Pagos/tablaPagos.vue')
    },
    computed: {
        ...mapGetters(["getIdTarjetaTxt", "getIdTarjeta"]),
    },
    watch: {
      "$route.params.mem"(newVal) {
        if (newVal) {
          this.numRecibo = newVal;
          this.getDatos();
        }
      }
    },
    mounted() {
      this.numRecibo = this.$route.params.recibo || null;
    },
    created(){
        this.getRecibosMembresia()
    },
    data() {
      return {
        numRecibo: null,
        recibos: [],
        tableActions: [
          'VER RECIBO'
        ],
      }
    },
    methods: {
        async getRecibosMembresia(){
            try {
                this.$loading(true)
                const response = await EndPointNet(
                    `payment/membresia?contrato=${this.getIdTarjeta}`,
                    'GET'
                )
                this.$loading(false)

                if (response.status === 200) {
                    this.recibos = response.data
                }
            } catch (error) {
                this.$loading(false)
                this.$mostrarSwal({
                    title: 'Error',
                    text: 'Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                    showCloseButton: true,
                    showConfirmButton: true,
                })
            }
        },
      async getRecibos() {
              try {
                  this.$loading(true)
                  const response = await EndPointNet(
                      `membresia?qry=${this.Nombremem || null}&idAgent=${this.idagenteSelect || null}&Colectivo=${this.idcolectividadSelect || null}`,
                      'GET'
                  )
                  this.$loading(false)
                  console.log(response)
                  if (response.status === 200) {
                      this.contratos = [...response.data];  // Copiar los datos para evitar referencias directas
  
                      this.activas = this.contratos?.filter(item => item.Estatus === 'ACTIVO').length;
                      this.suspendidas = this.contratos?.filter(item => item.Estatus === 'SUSPENDIDO').length;
                      this.totalbajas = this.contratos?.filter(item => item.Estatus === 'BAJA').length;
                  }
              } catch (error) {
                console.log(error)
                  this.$loading(false)
                  this.$mostrarSwal({
                      title: 'Error',
                      text: 'Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.',
                      icon: 'error',
                      confirmButtonText: 'Cerrar',
                      showCloseButton: true,
                      showConfirmButton: true,
                  })
              }
              this.idcolectividad= null
          },
      onActionSelected(action, item) {
        this.MembresiaSelect = {...item }
        switch (action) {
          case 'VER RECIBO':
                this.$verRecibo(item.Folio)
            break;
          default:
            break;
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
/* Estilo para el contenedor del título con ícono */
.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  font-family: 'Roboto', sans-serif;
}

/* Estilo para el ícono */
.title-icon {
  font-size: 36px; /* Tamaño del ícono */
  color: #4caf50; /* Verde para el ícono */
  margin-right: 8px; /* Espacio entre el ícono y el texto */
}

/* Estilo para el texto */
.title-text {
  font-size: 18px; /* Tamaño del texto */
  font-weight: 500; /* Peso medio para el texto */
  color: #333; /* Color oscuro para el texto */
  text-transform: uppercase; /* Mayúsculas */
  letter-spacing: 1px; /* Espaciado entre letras */
}
</style>