<template>
    <v-container>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
        <h1 class="text-h4 font-weight-thin mb-4">
          Asitencia
        </h1>
        <p>Da clic para registrar tu asistencia.</p>
        <v-btn
          color="success"
          class="mr-4"
          @click="registrar()"
          >
          Registrar
        </v-btn>
        </v-col>
        <v-row>
      </v-row>
      <div class="container-fluid mt-4">
        <v-data-table
            dense
            fixed-header
            :headers="headers"
            :items="listado"
            item-key="id"
            :search="search"
            >
        </v-data-table>
      </div>
    </v-container>
    </template>
  <script>
import { mapGetters } from 'vuex'
import EndPointNet from '@/functions/axios/axios-bearer'
  export default {
    computed: {
    ...mapGetters(['getIDE'])
  },
  async created () {
    await this.getAsistencia()
  },
  methods: {
    
    async registrar () {
      const response = await EndPointNet(
        `Asistencia`,
        'POST',
        {
          idUser: this.getIDE
        }
      )
      console.log(response)
  
      this.$swal({
        position: 'center',
        icon: 'success',
        title: 'Su asistencia fue registrada.',
        showConfirmButton: false,
        timer: 1500
      })
      await this.getAsistencia()
    },
    async getAsistencia () {
      console.log(this.date)
      const response = await EndPointNet(
        `Asistencia/${this.getIDE}`,
        'GET',
        {
          //iduser: this.getIDE,
          //date: this.date
        }
      )
      if (response.status === 200) {
        this.listado = response.data
      } else {
        alert('error')
      }
    }
  },
  data () {
    return {
      menu: false,
      dates: new Date().toJSON().slice(0, 10)?.replace(/-/g, '/'),
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      search: '',
      listado: [],
      headers: [
        { text: 'Fecha', value: 'FechaRegistroFormat' },
        { text: 'Id', value: 'Id' },
       // { text: 'IP', value: 'IP' }
      ],
      dialog: false
    }
  }
  }
  </script>
    <style lang="scss" scoped></style>
  