import axios from 'axios'

axios.defaults.baseURL = `${process.env.VUE_APP_BASE_API}`
axios.defaults.headers = {
  'Content-Type': 'application/json',
}
axios.defaults.withCredentials = false

const stateUser = {
  currentUser: {
    id: localStorage.getItem('id'),
    nombres: localStorage.getItem('nombres'),
    aPaterno: localStorage.getItem('aPaterno'),
    aMaterno: localStorage.getItem('aMaterno'),
    email: localStorage.getItem('email'),
    codAge: localStorage.getItem('codAge'),
    menu: localStorage.getItem('menu'),
    //urlBase: localStorage.getItem('urlBase'),
    //RFC: localStorage.getItem('RFC'),
  },
  //roles: JSON.parse(localStorage.getItem('roles')),
  acceptcookie: localStorage.getItem('acceptcookie'),
  token:
    localStorage.getItem('token') != null
      ? localStorage.getItem('token')
      : sessionStorage.getItem('token'),
  id: 0,
  idTarjetaTxt: localStorage.getItem('idTarjetaTxt'),
  idTarjeta: localStorage.getItem('idTarjeta'),
}

const gettersUser = {
  getName: (state) => `${state.currentUser.nombres}`,
  getApellidos: (state) => `${state.currentUser.aPaterno}`,
  getEmail: (state) => state.currentUser.email,
  isLogged: (state) => !!(state.token),
  getIDE: (state) => state.currentUser.id,
  //getRFC: (state) => state.currentUser.RFC,
  getCookie: (state) => !!(state.acceptcookie),
  getCODAGE: (state) => state.currentUser.codAge,
  getMenuOpcions: (state) => state.currentUser.menu,
  //geturlBase: (state) => state.currentUser.urlBase,
  // getRoles: (state) => state.roles,
  //inRole: (state) => (id) => state.roles.find((thing) => thing === id)
  getIdTarjetaTxt: (state) => state.idTarjetaTxt,
  getIdTarjeta: (state) => state.idTarjeta
}

const mutationsUser = {
  ACCEPT_COOKIE(state, acceptcookie) {
    localStorage.setItem('acceptcookie', `${acceptcookie}`)
    state.acceptcookie = acceptcookie
  },
  LOGIN_USER(state, userData) {
    localStorage.setItem('id', userData.id)
    localStorage.setItem('nombres', `${userData.nombres}`)
    localStorage.setItem('aPaterno', userData.aPaterno)
    localStorage.setItem('aMaterno', userData.aMaterno)
    localStorage.setItem('email', `${userData.email}`)
    localStorage.setItem('token', userData.token)
    //localStorage.setItem('roles', JSON.stringify(userData.roles))
    localStorage.setItem('codAge', userData.codAge)
    localStorage.setItem('menu', userData.menu)
    //localStorage.setItem('urlBase', userData.urlBase)
    localStorage.setItem('idTarjetaTxt', userData.idTarjetaTxt)
    localStorage.setItem('idTarjeta', userData.idTarjeta)

    state.currentUser.id = userData.id
    state.currentUser.nombres = userData.nombres
    state.currentUser.aPaterno = userData.aPaterno
    state.currentUser.aMaterno = userData.aMaterno
    // state.currentUser.RFC = userData.RFC
    state.currentUser.email = userData.email
    state.currentUser.token = userData.token
    state.currentUser.codAge = userData.codAge
    state.currentUser.menu = userData.menu
    // state.currentUser.urlBase = userData.urlBase
    //state.roles = userData.roles
    //axios.defaults.headers.common.Authorization = `Bearer ${userData.token}` //super importante
    state.idTarjetaTxt = userData.idTarjetaTxt
    state.idTarjeta = userData.idTarjeta
  },
  LOGOUT_USER() {
    localStorage.removeItem('id')
    localStorage.removeItem('token')
    localStorage.removeItem('nombres')
    localStorage.removeItem('aPaterno')
    localStorage.removeItem('aMaterno')
    // localStorage.removeItem('remember')
    localStorage.removeItem('email')
    localStorage.removeItem('codAge')
    localStorage.removeItem('menu')
    //localStorage.removeItem('urlBase')
    // localStorage.removeItem('RFC')
    // localStorage.removeItem('roles')
    // state.token = null
    // state.roles = null 
    // state.currentUser = []
    localStorage.removeItem('idTarjetaTxt')
    localStorage.removeItem('idTarjeta')
  }
}

const actions = {
  async login({ commit }, credentials) {
    return axios.post('/Auth', credentials).then(({ data }) => {
      if (data) {        
        console.log(data)
        commit('LOGIN_USER', data)
        return data.url
      }
    }).catch(error => {
      console.error('Error en la solicitud auth:', error);
      throw error;
    });
  },
  async iniciosesion({ commit }, credentials) {
    return axios.post('/Auth/user', credentials).then(({ data }) => {
      if (data) {
        commit('LOGIN_USER', data)
        return data.url
      }
    }).catch(error => {
      console.error('Error en la solicitud:', error);
      throw error;
    });
  },
  logout({ commit }) {
    commit('LOGOUT_USER')
  }

}

export default {
  state: stateUser,
  mutations: mutationsUser,
  getters: gettersUser,
  actions
}
