<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-subtitle>
        Puedes <strong>buscar</strong> por número de membresía, nombre del titular, nombre del contratante, agente o
        colectividad.
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <!-- Campo de búsqueda -->
          <v-col cols="12" md="3">
            <v-text-field clearable class="col" v-model="Nombremem" label="Titular / membresía / contratante" outlined
              dense @change="changeSearch" @keyup.enter="Buscar">
            </v-text-field>
          </v-col>
          <!-- Selector de agentes -->
          <v-col cols="12" md="3">
            <list-agentes @agent-change="changeAgent"></list-agentes>
          </v-col>
          <!-- Selector de colectivos y botón de descarga -->
          <v-col cols="12" md="4">
            <select-colectivos 
              :colectivos="colectivos" 
              @colectivo-change="changeColectividad">
            </select-colectivos>
          </v-col>
          <!-- Botón de búsqueda -->
          <v-col cols="12" md="2">
            <v-btn color="azulfuerte" @click="Buscar" dark>
              Buscar
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-show="verOptDownloadCole">
          <v-btn color="azul_med" @click="DescargaZip" dark small class="mt-2">
            Descargar tarjetas
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <tabla-membresias :membresias="this.contratos" :actions="tableActions" @action-selected="onActionSelected">
    </tabla-membresias>

    <!-- <list-pagos 
      :isOpen.sync="isModLisPagos" 
      :Membresia="this.MembresiaSelec">
    </list-pagos>
    <modal-pago 
      v-model="dialogPagoShow" 
      :item="selectedPago" 
      @save="aplicaPagoActiva"
    ></modal-pago> -->
    <list-beneficiarios 
      :isOpen.sync="dialogBeneficiados" 
      :Membresia="this.MembresiaSelect?.GUID || ''"
    ></list-beneficiarios>

    <pases-medicos-dialog
      :isOpen.sync="dialogPases" 
      :item="this.MembresiaSelect"
      >
    </pases-medicos-dialog>

  </v-container>
</template>
<script>

import EndPointNet from "@/functions/axios/axios-bearer";


export default {
  name: 'Membresias',
  components: {
    SelectColectivos: () => import('@/components/selectColectivo.vue'),
    ListAgentes: () => import('@/components/Forms/AgentesListComponent.vue'),
    TablaMembresias: () => import('@/components/TablaMembresias.vue'),
    //ListPagos: () => import('@/components/Pagos/modalListPagosComponent.vue'),
    ListBeneficiarios: () => import('@/components/ListBeneficiariosComponent'),
    //ModalPago: () => import('@/components/Pagos/PagoComponent.vue'),
    PasesMedicosDialog: () => import('@/components/PasesMedicosDialog.vue'),

  },
  watch: {
    "$route.params.mem"(newVal) {
      if (newVal) {
        this.Nombremem = newVal;
        this.getMembresias();
      }
    }
  },
  mounted() {
    this.Nombremem = this.$route.params.mem || null;
    this.getColectivos() 
  },
  data() {
    return {
      colectivos: [],

      isModLisPagos: false,
      dialogBeneficiados: false,
      dialogPagoShow: false,
      dialogPases: false,
      isModalCitas: false,


      MembresiaSelect: {},


      Nombremem: null,
      verOptDownloadCole: false,
      contratos: [],
      idagenteSelect: null,
      idcolectividadSelect: null,
      tableActions: [
        //'BENEFICIADOS',
        //'RECIBOS',
        //'PASES MEDICOS',
        'TARJETA DIGITAL',
        //'URGENCIAS',
        //'CONTRATO'
      ],
    }
  },
  methods: {
    async getColectivos() {
        try {
            this.$loading(true)
            const response = await EndPointNet(
                `colectivo/admin`,
                'GET'
            )
            this.$loading(false)

            if (response.status === 200) {
                this.colectivos = response.data
            }
        } catch (error) {
            this.$loading(false)
            this.$mostrarSwal({
                title: 'Error',
                text: 'Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.',
                icon: 'error',
                confirmButtonText: 'Cerrar',
                showCloseButton: true,
                showConfirmButton: true,
            })
        }
    },
    changeColectividad(value) {
      this.idcolectividadSelect = value
      this.verOptDownloadCole = value !== null;
      if (value) {
        this.idagenteSelect = null
        this.Nombremem = null
      }
    },
    changeAgent(value) {
      this.idagenteSelect = value
      if (value) {
        this.idcolectividadSelect = null
        this.Nombremem = null
      }
    },
    async getMembresias() {
            try {
                this.$loading(true)
                const response = await EndPointNet(
                    `membresia?qry=${this.Nombremem || null}&idAgent=${this.idagenteSelect || null}&Colectivo=${this.idcolectividadSelect || null}`,
                    'GET'
                )
                this.$loading(false)
                console.log(response)
                if (response.status === 200) {
                    this.contratos = [...response.data];  // Copiar los datos para evitar referencias directas

                    this.activas = this.contratos?.filter(item => item.Estatus === 'ACTIVO').length;
                    this.suspendidas = this.contratos?.filter(item => item.Estatus === 'SUSPENDIDO').length;
                    this.totalbajas = this.contratos?.filter(item => item.Estatus === 'BAJA').length;
                }
            } catch (error) {
              console.log(error)
                this.$loading(false)
                this.$mostrarSwal({
                    title: 'Error',
                    text: 'Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                    showCloseButton: true,
                    showConfirmButton: true,
                })
            }
            this.idcolectividad= null
        },
    DescargaZip() {
      this.$loading(true);
      var urltarjet = `${process.env.VUE_APP_FORMATOS}Tarjeta/Colectividad?guid=${this.idcolectividad}`;
      var newTab = window.open(urltarjet);
      if (newTab) {
        newTab.focus();
        this.$loading(false);
      }
    },
    Buscar() {
      this.getMembresias()
    },
    changeSearch() {
      this.idcolectividadSelect = null
      this.idagenteSelect = null
    },
    onActionSelected(action, item) {
      console.log(`Acción seleccionada: ${action} para el item`, item);
      this.MembresiaSelect = {...item }
      switch (action) {
        case 'BENEFICIADOS':
          this.dialogBeneficiados = true
          break;
        case 'PASES MEDICOS':
          this.dialogPases = true
          break;
        case 'RECIBOS':
          this.dialogPases = true
          break;
        case 'TARJETA DIGITAL':
          this.$onDescargaTarjeta(item.Id)
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>