import Vue from 'vue'
import 'sweetalert2/dist/sweetalert2.min.css';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueLoading from 'vuejs-loading-plugin'
import VueSweetalert2 from 'vue-sweetalert2'
import CurrencyFormatPlugin from './plugins/currencyFormatPlugin';
import mixins from './mixins';
import { VueMaskDirective } from 'v-mask'
import VueCurrencyFilter from 'vue-currency-filter'
import GlobalMethodsPlugin from './plugins/globalMethods';
import CryptoJS from 'crypto-js';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false
Vue.mixin(mixins); 
Vue.directive('mask', VueMaskDirective); //atributos especiales prefijo v- para agregar interactividad o manipular el DOM

Vue.prototype.$mostrarSwal = function ({ title, text, icon, confirmButtonText, timer, showCloseButton, showConfirmButton }) {
  this.$swal({
    title,
    text,
    icon,
    confirmButtonText,
    showCloseButton,
    showConfirmButton,
    timer: timer || null
  });
};
Vue.prototype.$setTitle = function (title) {
  document.title = title ? `${title} - Medicover` : 'MediCover';
  Vue.prototype.$seccion = title ? `${title}` : 'MiAplicación';
};
Vue.use(VueMeta, {
  // Configuración global de meta
  refreshOnceOnNavigation: true,
});
Vue.use(VueCurrencyFilter,
  {
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 0,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true
  })
Vue.use(CurrencyFormatPlugin)
Vue.use(GlobalMethodsPlugin)
Vue.use(VueSweetalert2)
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Cargando...', // default 'Loading'
  background: 'rgba(223, 223, 223, 0.500)' // set custom background
  // classes: ['myclass'], // array, object or string
})
Vue.prototype.$encrypta = function(ide){

    const key = CryptoJS.enc.Utf8.parse('your-secret-key-123');
    const iv = CryptoJS.enc.Utf8.parse('your-initial-vector-12');
    
    // Encriptar los datos usando AES
    const encrypted = CryptoJS.AES.encrypt(ide, key, { iv: iv }).toString().replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return encrypted;
  
},
Vue.prototype.$formatDate = function(date) {
  if(date){
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' }; //long
    return new Date(date).toLocaleDateString('es-ES', options);
    }
};
Vue.prototype.$truncatedText = function(text){
  const words = text.split(' ');
  if (words.length > 3) {
    return words.slice(0, 3).join(' ') + '...';
  }
  return text;
}
Vue.prototype.$onDescargaTarjeta = function(num) {
  this.$loading(true)    
  var urltarjet = `${process.env.VUE_APP_FORMATOS}tarjeta?guid=${num}`
  var newTab = window.open(urltarjet);
  if (newTab) {
    newTab.focus();
    this.$loading(false)
  }
},
Vue.prototype.$copyText = function(text){
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.$swal({
          position: '',
          html: `Texto copiado`,
          icon: 'success',
          confirmButtonText: 'Cerrar',
          showCloseButton: false,
          showConfirmButton: false,
          timer: 1000,
          backdrop: false,
        })
        })
        .catch((error) => {
          console.error("Error al copiar el texto: ", error);
        });
    }
    else {
    // Fallback para navegadores más antiguos
    // Crear un elemento de texto temporal
    let inputTemp = document.createElement("input");
    inputTemp.value = text;
    document.body.appendChild(inputTemp);

    // Seleccionar el contenido del input
    inputTemp.select();
    document.execCommand("copy");

    // Eliminar el input temporal
    document.body.removeChild(inputTemp);

    this.$swal({
          position: '',
          html: `Texto copiado`,
          icon: 'success',
          confirmButtonText: 'Cerrar',
          showCloseButton: false,
          showConfirmButton: false,
          timer: 1000,
          backdrop: false,
        })
    }
}
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
