<template>
    <v-container>
        <div class="d-flex flex-wrap align-center justify-end">
            <v-chip class="ma-2" color="azul_med" label dark>
                <v-icon start>mdi-chevron-right-circle-outline</v-icon>
                <v-list-item-subtitle>Activas: {{ this.activas }}</v-list-item-subtitle>
            </v-chip>
            <v-chip class="ma-2" color="rojoalert" label dark>
                <v-icon start>mdi-alert-circle-outline</v-icon>
                <v-list-item-subtitle>Impago: {{ this.suspendidas }}</v-list-item-subtitle>
            </v-chip>
            <v-chip class="ma-2" color="grey" label dark>
                <v-icon start>mdi-alert-circle-outline</v-icon>
                <v-list-item-subtitle>Bajas: {{ this.totalbajas }}</v-list-item-subtitle>
            </v-chip>
        </div>
        <p class="font-weight-regular">
            Listado de <strong>membresías</strong>, puedes agrupar por colectividad.
        </p>
        <v-row class="bordered-row">
            <v-col xs="12" sm="12" md="6" lg="4" xl="4">
                <select-colectivos :colectivos="colectivos" @colectivo-change="changeColectividad"></select-colectivos>
            </v-col>
            <v-col xs="12" sm="12" md="2" lg="2" xl="2">
                <v-btn color="azul_med" @click.native="buscarColectivo" dark>Buscar</v-btn>
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-col sm="12" md="4" lg="4" xl="4">
                <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar en listado"
                    single-line hide-details outlined dense></v-text-field>
            </v-col>
        </v-row>
        <tabla-membresias 
        :membresias="this.membresias" 
        :actions="tableActions"
         @action-selected="onActionSelected">
        </tabla-membresias>
    </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
import EndPointNet from "@/functions/axios/axios-bearer";


export default {
    name: 'AgenteMembresias',
    computed: {
        ...mapGetters(['getCODAGE'])
    },
    components: {
        SelectColectivos: () => import('@/components/selectColectivo.vue'),
        TablaMembresias: () => import('@/components/TablaMembresias.vue')
    },
    async mounted() {
        await this.getColectivos()
        await this.getMembresias()
    },
    data() {
        return {
            activas: 0,
            suspendidas: 0,
            totalbajas: 0,

            colectivos: [],
            search: '',

            idcolectividad: null,
            contratos: [],
            membresias: [],


            headers: [
                { text: "#", value: "index", align: "start", width: 10 },
                { text: 'Agente', value: 'Agente', sortable: false, width: 100 },
                { text: 'Membresia', value: 'GUID', sortable: true, width: 80 },
                { text: 'Plan', value: 'Plan', sortable: false, width: 150 },
                { text: 'Vigencia Inicio', value: 'FecIniVig', sortable: true, width: 50 },
                { text: 'Alta', value: 'FecAlta', sortable: true, width: 50 },
                { text: 'Contratante', value: 'Contratante', sortable: false },
                { text: 'Titular', value: 'Titular', sortable: false },
                { text: 'Estatus', value: 'Estatus', sortable: true },
                { text: 'Opciones', value: 'Membresia', sortable: false },
            ],
            tableActions: [
                //'BENEFICIADOS',
                //'RECIBOS',
                //'PASES MEDICOS',
                'TARJETA DIGITAL',
                //'URGENCIAS',
                //'CONTRATO'
            ],
            customFooterProps: {
                itemsPerPageText: "Elementos por página:",
                itemsPerPageAllText: "Todos",
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'Filas por página'
            }
        }
    },
    methods: {
        changeColectividad(item) {
            this.idcolectividad = item
        },
        onActionSelected(action, item) {
            this.MembresiaSelect = {...item }
            switch (action) {
                case 'BENEFICIADOS':
                 //this.dialogBeneficiados = true
                break;
                case 'PASES MEDICOS':
                 //this.dialogPases = true
                break;
                default:
                break;
            }
            },
        async getColectivos() {
            try {
                this.$loading(true)
                const response = await EndPointNet(
                    `colectivo/agente?idAgent=${this.getCODAGE}`,
                    'GET'
                )
                this.$loading(false)

                if (response.status === 200) {
                    this.colectivos = response.data
                }
            } catch (error) {
                this.$loading(false)
                this.$mostrarSwal({
                    title: 'Error',
                    text: 'Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                    showCloseButton: true,
                    showConfirmButton: true,
                })
            }
        },
        buscarColectivo() {
            console.log(this.idcolectividad)
            this.getMembresias()
        },
        async getMembresias() {
            try {
                this.$loading(true)
                const response = await EndPointNet(
                    `membresia/agente?idAgent=${this.getCODAGE || null}&Colectivo=${this.idcolectividad || null}`,
                    'GET'
                )
                this.$loading(false)

                if (response.status === 200) {
                    this.membresias = response.data
                    this.activas = this.membresias.filter(item => item.Estatus === 'ACTIVO').length;
                    this.suspendidas = this.membresias.filter(item => item.Estatus === 'SUSPENDIDO').length;
                    this.totalbajas = this.membresias.filter(item => item.Estatus === 'BAJA').length;
                } else {
                    this.$mostrarSwal({
                        title: 'Información',
                        text: 'No tienes pases suficientes. Puedes hacer una recarga de 5 a un costo especial, contacta a tu agente.',
                        icon: 'warning',
                        confirmButtonText: 'Aceptar',
                        showCloseButton: true,
                        showConfirmButton: true,
                    })
                }
            } catch (error) {
                this.$loading(false)
                this.$mostrarSwal({
                    title: 'Error',
                    text: 'Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                    showCloseButton: true,
                    showConfirmButton: true,
                })
            }
            this.idcolectividad= null
        }
    }
}
</script>

<style scoped>
/* Puedes agregar estilos específicos para esta vista */
</style>